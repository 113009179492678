import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`OKR's 101`}</h2>
    <ol>
      <li parentName="ol">{`OKRs are a tool organizations use to focus on and achieve our goals`}</li>
      <li parentName="ol">{`OKRs help focus our work on the actions that create the most impact`}</li>
      <li parentName="ol">{`Effective OKRs should relate to our day to day actions and decisions`}</li>
      <li parentName="ol">{`OKRs should serve as our basis for quarterly roadmapping and bi-weekly sprint planning`}</li>
      <li parentName="ol">{`OKRs help us understand when we need to upgrade our engine`}</li>
    </ol>
    <h2>{`1. OKRs are a tool organizations use to focus on and achieve our goals`}</h2>
    <p>{`OKR stands for Objective and Key Results. It was a tool created at Intel to help the organization focus as they were growing quickly. Since then, they've been adopted by Google, the Gates Foundation (widely considered the most effective non-profit in history), and countless other organizations.`}</p>
    <p>{`OKRs are similar to metrics, but they help ensure a clearer focus on metrics that matter. The Objective is a descriptor of what we are trying to achieve, while Key Results give us a way to benchmark whether we are making efficient progress towards the Objective.`}</p>
    <p>{`As an organization, we'll track and collect data on hundreds of metrics. Many will be helpful to understand our work. But it's critical to identify which metrics are the most important for us to be constantly monitoring. Our OKRs will give us a window into how we are progressing against our mission and help us prioritize our work to achieve stronger results.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Example: UN Climate Change OKR`}</strong></p>
    <p><strong parentName="p">{`Objective:`}</strong></p>
    <p>{`Limit climate impact caused by carbon emissions`}</p>
    <p><strong parentName="p">{`Key results:`}</strong></p>
    <ul>
      <li parentName="ul">{`Get 200 countries signed on to new climate accord by 2021`}</li>
      <li parentName="ul">{`Reduce CO2 output by XXX ppm by 2030`}</li>
      <li parentName="ul">{`Sequester XXX ppm of CO2 by 2050`}</li>
    </ul>
    <hr></hr>
    <p>{`A few best practices around OKRs:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Objectives should be ambitious.`}</strong>{` We should feel good about achieving 60-70% of our OKRs. If we achieve more, we should set more ambitious goals next time. If we achieve less, we should refine our goals to be more achievable.`}</li>
      <li parentName="ul"><strong parentName="li">{`Key Results should be quantifiable and measurable.`}</strong>{` We should be able to calculate whether we achieved them and two people shouldn't be able to debate the result.`}</li>
      <li parentName="ul"><strong parentName="li">{`OKRs are only one factor in performance reviews.`}</strong>{` Success on OKRs are often dependent on other team members, and on whether the OKRs are defined well. So it's possible to have strong performance but miss on your OKRs.`}</li>
    </ul>
    <p>{`Effectively implementing OKRs at Make School is especially critical given the industry we sit in. Much of higher ed funding comes from the federal government. For social service programs, the federal government attempts to measure impact for less than 10% of it's spend. The lack of measurement and data is largely responsible for the overfocus on inputs rather than outcomes of higher ed (this is the thesis for a policy paper we're writing for the Manhattan Institute and Gates Foundation).`}</p>
    <p>{`One of the core reasons we've found early success is we've consistently focused on student placement as an org wide Key Result. In just a few years, our average starting salary is on par with or higher than all CS degree programs. Holding this as an OKR has helped us define our strategy and prioritize our work. Instead of broadening our set of majors to match traditional institutions, we've stayed focused on a single major that has the strongest student outcomes. When implemented correctly, OKRs will drive different organizational behaviors.`}</p>
    <hr></hr>
    <h2>{`2. OKRs help focus our work on the actions that create the most impact`}</h2>
    <p>{`We each spend 40-60 hours per week working. We generally know that the work we are doing supports our mission, and that our mission correlates to our org wide Key Results. But we often spend time working on things that don't significantly impact our core goals.`}</p>
    <p>{`As we think about scaling Make School, it becomes increasingly important to reduce the time we spend on low impact activities and increase the time we spend on high impact ones. This is called "working smart, not hard" or "working efficiently". If we can increase our efficiency, we'll be able to make our workloads more sustainable and keep our tuition affordable for our students. It's important to note that "increasing efficiency" is `}<strong parentName="p">{`not`}</strong>{` synonymous with spending less time with students. If spending time with students has high impact on learning outcomes and NPS, we should do more of it and reduce the time we spend elsewhere.`}</p>
    <p>{`The hardest parts of prioritizing impactful activities are: 1. Effectively evaluating the impact each initiative has and 2. Being deliberate about saying no to low impact initiatives / activities.`}</p>
    <p>{`Certain tasks we do have very clear links between action and impact. We can send an email to a list of prospective students with a webinar signup and accurately know how many of them signed up. Other tasks have very indirect (or long) links between action and impact. It would be really hard to measure the impact of staying late on a Friday evening to help a junior student on that student's happiness or average starting salary.`}</p>
    <p>{`In the cases where there is an indirect or long link between action and impact, we need to find creative ways to approximate these links. The process for doing so is similar to the scientific method. First, we come up with a hypothesis based on our intuition: "spending more 1:1 time with students will improve academic progress and student satisfaction". Then we design an experiment where we can measure the impact of an action: "I'll double my office hours for my CS 101 course and measure how that impacts the pass/fail rate and course NPS. I'll also ask a question on the end of term survey to see whether students felt it was useful". Then we run the experiment, and share the findings back to the group.`}</p>
    <p>{`Approximating these links is a `}<strong parentName="p">{`very`}</strong>{` imperfect science. There are often confounding variables and the links aren't easy to define. So the results often end up being "best guess estimates" (or qualitative surveys) rather than perfect objective data. However, the practice of running experiments and measuring results helps us ask the "why" behind what we're doing and adds intellectual rigor to the way we design our institution. The ultimate value of OKRs is in helping implement a culture and process of measuring impact, running experiments, and asking why we are doing what we are doing.`}</p>
    <p>{`Running this process also gives us a basis to prioritize tasks, say no to tasks, and stop doing tasks. Saying no is inherently uncomfortable, especially when it's something a colleague has asked you to do or something a student is expecting from you. But it's a lot easier when they have a clear understanding of priorities, and why you're saying no.`}</p>
    <p>{`When a team is aligned on what the most important objectives are and what key results they are targeting, it can become much easier to pick between ideas or say no to ideas. Having a basis for these types of decisions can remove the politics or emotions that come with debates about which ideas to pursue. Instead, it can be a rational decision based on an agreed upon set of priorities and principles. Similarly, if a team member's OKRs and priorities are clearly visible to others in the org, it can be easier for others to understand why they said no.`}</p>
    <p>{`The process of asking the "why" behind the work and prioritizing for most impact can also help drive out of the box thinking and encourage us to implement things in ways others haven't tried before.`}</p>
    <p>{`Take the example of a classroom. Most institutions have looked for ways to create efficiency in courses by improving lectures. They've increased the number of students in each lecture, and put the lectures online so students could refer to them later and solidify their learning. But they still bake in the assumption that a lecture is critical for student learning. By approaching the problem from the end goal - to improve student learning - you might instead design a project based course without lectures where peers collaborate and learn from each other.`}</p>
    <p>{`If we aim to innovate on our education, we should continue to use our OKRs and experiments as a basis to challenge our assumptions of what makes an effective education. We should cut tasks that we've done in the past or that other institutions are doing, unless we can provide evidence for how those actions are significantly impacting our objectives.`}</p>
    <hr></hr>
    <h2>{`3. Effective OKRs should relate to our day to day actions and decisions`}</h2>
    <p>{`As you look to define your team and individual OKRs, you should consider whether measuring your Key Results will actually drive you to change your behavior. If measuring the Key Results aren't creating changes in behavior, then there's little value in measuring them.`}</p>
    <p>{`OKRs should serve to help you answer "am I on the right track or do I need to change direction?". This means your OKRs can't be so broad that they are disconnected from your day to day tasks, but also not so narrow that it's hard to link their impact to the overall organization goals.`}</p>
    <p>{`When thinking about Key Results, it's important to understand the distinction between primary metrics and indicator metrics. Primary metrics are best measured when a cycle is complete, and it's the primary metric you are optimizing for. Examples of primary metrics are:`}</p>
    <ul>
      <li parentName="ul">{`Collect 500 contact cards from school visits by December`}</li>
      <li parentName="ul">{`Achieve 60 NPS of CS 101 course`}</li>
      <li parentName="ul">{`Raised $15m in funding by Dec 2018`}</li>
      <li parentName="ul">{`Receive 1,000 applications by application deadline`}</li>
    </ul>
    <p>{`Primary metrics help us evaluate at the end of the cycle - whether it's one term, one quarter, or one year - whether we've been successful at achieving our objective. These are the metrics that ultimately matter most. But they are reactive, we don't know where we stand until the end of the cycle. So they don't serve as useful data in the middle of the cycle to help us know whether we are on track or whether we should change our approach.`}</p>
    <p>{`It's best to supplement primary metrics with indicator metrics to help give us more actionable insights mid-cycle. An indicator metric is something that's correlated with a primary metric but can be measured far earlier. In some cases, the indicator could simply be a fraction of the primary metric but in most cases the relationship is more complex. The relationship could be algebraic - meaning the indicator metric has a linear correlation to the primary - or derivative - meaning the indicator metric is intended to measure the pace of activity rather than the result - or something else. It's ideal for the indicator metric to have clear mathematical link to the primary metric (to ensure strong correlation), but that's not always possible. (1)`}</p>
    <p><strong parentName="p">{`Example 1:`}</strong></p>
    <ul>
      <li parentName="ul">{`Key Result (primary): Collect 500 contact cards from school visits by December`}</li>
      <li parentName="ul">{`Key Result (indicator): Collect 250 contact cards from school visits by October`}</li>
      <li parentName="ul">{`Mathematical relationship: direct`}</li>
    </ul>
    <p><strong parentName="p">{`Example 2:`}</strong></p>
    <ul>
      <li parentName="ul">{`Key Result (primary): Achieve 60 NPS of CS 101 course`}</li>
      <li parentName="ul">{`Key Result (indicator): Average % attendance of class`}</li>
      <li parentName="ul">{`Mathematical relationship: none, assumption that higher attendance means higher NPS`}</li>
    </ul>
    <p><strong parentName="p">{`Example 3:`}</strong></p>
    <ul>
      <li parentName="ul">{`Key Result (primary): Raise $15m in funding by Dec 2018`}</li>
      <li parentName="ul">{`Key Result (indicator): Have 10 second round meetings by Nov 2018`}</li>
      <li parentName="ul">{`Mathematical relationship: 10% of meetings will lead to term sheets (gut estimate)`}</li>
    </ul>
    <p><strong parentName="p">{`Example 4:`}</strong></p>
    <ul>
      <li parentName="ul">{`Key Result (primary): Receive 1,000 applications by March deadline`}</li>
      <li parentName="ul">{`Key Result (indicator): Receive 1,000 drafts by January`}</li>
      <li parentName="ul">{`Mathematical relationship: 50% drafts convert to applications (based on historical data)`}</li>
    </ul>
    <p><strong parentName="p">{`Example 5:`}</strong></p>
    <ul>
      <li parentName="ul">{`Key Result (primary): Receive 1,000 applications by March deadline`}</li>
      <li parentName="ul">{`Key Result (indicator): Execute 50 experiments with potential to convert 40 applicants`}</li>
      <li parentName="ul">{`Mathematical relationship: 50% of experiments will succeed, yielding 1,000 applicants`}</li>
    </ul>
    <p>{`There aren't perfect ways to determine indicator metrics, since the relationships aren't always clear. So it's best to pick indicator metrics that align with the work you're actively doing to help achieve the primary goal and that will likely have the most impact on the primary goal. If you notice that you performed well against the indicator metric but not the primary metric, that's probably a sign that the correlation wasn't strong enough and you should pick a different indicator metric next time. If you're continuing to experiment with different actions and different indicator metrics, it's normal to have misses. Again, a big part of the value in doing this is for us to learn more about what works and what doesn't.`}</p>
    <p>{`If you're spending a lot of time on actions that aren't directly linked to your primary or indicator metrics, it's good to dig deep and ask why you're spending time on those actions. It could be that you can cut some of that work! Or it could be that there is an indirect correlation between those actions and the primary goal, and even though it's not fully visible it's still important (in theory, this is something you could test). Or maybe the action is in service of a different goal or a longer term goal that isn't reflected in your OKRs. In the latter case, it could be good to add something in your OKRs to help reflect that this work is still important (rooted in the "why"). Adding more OKRs should should be a last resort as it makes your work harder to prioritize. Instead you could make an active decision to make this a priority (and include it in your OKRs) next cycle rather than this cycle.`}</p>
    <p>{`Keep in mind, there isn't one right answer on how to construct OKRs. They are a tool to help us ask the right questions and create process. We'll continuously iterate on them in order to improve and adapt them over time.`}</p>
    <hr></hr>
    <h2>{`4. OKRs should serve as our basis for quarterly roadmapping and bi-weekly sprint planning`}</h2>
    <p>{`The most important part of implementing OKRs is using them on a regular basis. The two processes that rely most heavily on OKRs are quarterly roadmapping and bi-weekly sprint planning.`}</p>
    <p>{`When quarterly roadmapping, our goal is to determine the most important projects for us to be working on. Ideally, we're looking to roadmap 3-4 big projects per team per quarter and 3-4 medium sized projects per person per quarter (for instructors, 1-2 of those big projects could be delivering courses).`}</p>
    <p>{`If a team has a number of large projects supporting their team-level OKRs, they could distribute those projects to team members during roadmapping prior to setting individual OKRs. Alternatively, they could divide projects into components and distribute to team members.`}</p>
    <p>{`To determine what projects go in the roadmap, we should start with our Key Results for the year and determine a draft strategy we plan to use to achieve those Key Results. The strategy will give us a sense for what high level initiatives we'll pursue and what indicator metrics we can use to track quarterly progress against our annual Key Results. From there, we can determine the projects we'll undertake to help us pursue the strategy and ensure that we're not taking on too many projects for the size of our team.`}</p>
    <p>{`This process usually takes some iteration. It's easy to sign up for too many projects, or pick projects that won't directly impact our indicator or primary metrics, or pick projects where progress isn't measurable. If that happens, we re-evaluate the strategy and adjust to make sure the work we are undertaking has maximum and measurable impact on our OKRs.`}</p>
    <p>{`Roadmapping and "strategy planning" are more or less synonymous. It's the process of connecting our day to day projects with our OKRs. At Make School, we centralize goal setting - our leadership team establishes a small set of org wide OKRs to align with our mission - while decentralizing strategy - team leads work with their teams to determine what projects to undertake and what resources (headcount, budget) they need in order to meet our OKRs.`}</p>
    <p>{`OKRs should always be the root of our strategy. We should only take on projects that directly impact our OKRs. Often organizations mistakenly reverse this process. They start by brainstorming projects that seem like good ideas then shoehorn them into a strategy - but this is a recipe for chaos and overwork. It's OK to brainstorm a list of projects, but they should only enter a roadmap if they are determined to be among the most impactful projects to help meet our OKRs. Not every project we work on will appear in our roadmaps - some may be too small or longer term - but the largest and most critical ones should. These larger and more critical projects will be the ones we focus on accomplishing, even if it means dropping other work.`}</p>
    <p>{`Zooming in from roadmaps, most teams leverage 2 week sprints to help plan their day to day work. Sprint planning starts with a backlog of tasks - ideas you've had or requests you've received. It's best to keep the backlog organized by marking which project or OKR the task supports, how long the task will take, and how much impact the task will have on OKRs (eg. is it critical, important, or nice to have). You may decide to separate backlogs by project if you're working cross-functionally. Asana makes it really easy to create and manage backlogs tag tasks with these indicators.`}</p>
    <p>{`Once you have an organized backlog, you can start selecting which tasks you plan to accomplish in the next two weeks. You should prioritize the ones that have the most impact on your OKRs (relative to the time they will take). You won't be able to perfectly rank tasks based on this, but it's a good exercise to try. You might want to find ways to collect data to help better prioritize and to challenge your assumptions on why you think something is most important. Effective sprint planning will ensure you make strong progress against your strategy and help connect your day to day work with your OKRs. It will also help ensure you don't take on too much work by giving you a heuristic to determine what work to focus on and what to say no to.`}</p>
    <hr></hr>
    <h2>{`5. OKRs help us understand when we need to upgrade our engine`}</h2>
    <p>{`As our OKRs continue to get more ambitious, we'll often notice limits that we're starting to hit as individuals or as a team. These limits won't be able to be overcome by doing more work in the same manner that we've been doing in the past. This is typical of growing organizations.`}</p>
    <p>{`At these moments, it's important for us to think deeper about how we can upgrade our engine to overcome these limits. The upgrade could come in the form of designing a new process, cutting other initiatives, implementing a new tool, or hiring someone new (though this should be seen as a last resort).`}</p>
    <p>{`Scaling effectively is not just about meeting this year's OKRs, it's also about constantly re-evaluating how we can reinvent the way we work in order to do more with fewer resources. We'll dive into how we can upgrade our engine in future sessions on Project Management and Systems and Scale.`}</p>
    <hr></hr>
    <h2>{`Additional Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.whatmatters.com/resources/googles-okr-playbook"
        }}>{`Guide on writing good OKRs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://rework.withgoogle.com/guides/set-goals-with-okrs/steps/introduction/"
        }}>{`Step by step goal setting`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://library.gv.com/how-google-sets-goals-okrs-a1f69b0b72c7"
        }}>{`Lecture on OKRs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.amazon.com/Measure-What-Matters-Google-Foundation/dp/0525536221/ref=pd_sbs_14_3/145-8867479-5553567"
        }}>{`Book on OKRs`}</a></li>
    </ul>
    <p><em parentName="p">{`Thanks to Courtney, Erin, Michelle, Dan, Simon, and Ellie for content, questions, and feedback!`}</em></p>
    <hr></hr>
    <p>{`(1) `}<em parentName="p">{`The field of data science serves to help organizations identify indicator metrics that can better predict results of primary metrics. Machine learning goes a step further by running complex analysis on all available data to discover unexpected correlations. Both rely on sufficiently large sets of data to ensure the correlations aren't coincidental, so they aren't suited for use at our scale.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      